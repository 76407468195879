::-webkit-input-placeholder {
  font-family: "Poppins";
  font-weight: 700;

  color: #012c3d;
  opacity: 0.5;
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-family: "Poppins";
  font-weight: 800;
  color: #f12c3d;
  opacity: 0.5;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Poppins";
  font-weight: 800;
  color: #f12c3d;
  opacity: 0.5;
}
:-moz-placeholder {
  /* Firefox 4 - 18 */
  font-family: "Poppins";
  font-weight: 800;
  color: #f12c3d;
  opacity: 0.5;
}
