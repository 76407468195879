.clickChoice:focus {
  cursor: "pointer";
  color: "#EB2735";
  border-color: "#EB2735";
  background-color: "#ffffff";
  border: "1px solid";
  border-top-right-radius: "25px";
  border-bottom-right-radius: "25px";
  border-top-left-radius: "25px";
  border-bottom-left-radius: "25px";
  font-family: "'Montserrat', sans-serif";
  font-size: "18px";
  font-weight: "600";
  text-align: "center";
  width: "220px";
  padding: "0px";
  display: "inline-block";
  margin-left: "10%";
}

.CoolBorder {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  border-top-left-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  font-family: "Poppins";
  font-weight: 700;
}
